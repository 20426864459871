<template>
  <li
    class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
  >
    <div class="flex-1 flex flex-col p-4">
      <img
        class="w-40 h-40 flex-shrink-0 mx-auto bg-black rounded-full object-cover"
        :src="this.img"
      />
      <h3 class="mt-2 text-gray-900 text-md font-medium">{{ dish.name }}</h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">precio y calorias</dt>
        <dd class="text-gray-500 text-sm">
          {{ dish.totalcal }} | $ {{ dish.totalprice }}
        </dd>
        <dt class="sr-only">Status</dt>
        <dd class="mt-3">
          <span
            class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full"
            >STATUS</span
          >
        </dd>
      </dl>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    dish: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      img: this.dish.img
    };
  }
};
</script>

<style></style>
