<template>
  <main v-if="dish" class="w-full flex flex-col items-center">
    <Navbar></Navbar>
    <div class="flex flex-wrap max-w-4xl justify-center w-full">
      <!--DISHE-->

      <div class="w-full flex flex-col">
        <img
          :src="this.img"
          class="w-full md:w-10/12 object-cover self-center mb-4"
        />

        <h1 class="px-2 pt-2 uppercase font-bold text-gray-700 ">
          {{ dish.name }} ({{ optionsSelected }} )
        </h1>

        <div class=" flex w-full flex-col items-center justify-center">
          <p class="px-2 text-indigo-500 text-2xl font-semibold my-4">
            {{ completeCal }} <span class="text-gray-500 text-lg">Cal | $ </span
            >{{ completePrice }}
          </p>
          <div class="flex w-full ">
            <div class="w-1/2">
              <ul class="p-4 text-gray-500 text-sm">
                <li
                  v-for="(ingredient, index) in dish.ingredients"
                  :key="index"
                >
                  &#8226; {{ ingredient }}
                </li>
              </ul>
            </div>
            <div v-if="extrasSelected.length" class="border-l w-1/2">
              <ul class="p-4 text-gray-500 text-sm">
                <li v-for="(extra, index) in extrasSelected" :key="index">
                  &#8226; {{ extra.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dish.radioptions" class="mt-8 w-full flex flex-col">
        <Radioptions
          @changed="updatePrice"
          v-for="group in dish.radioptions"
          :key="group.name"
          :group="group"
        >
        </Radioptions>
      </div>

      <div v-if="dish.extras" class="mt-8 w-full flex flex-col">
        <h1 class="px-2 pt-2 mb-2 uppercase font-bold text-gray-700">
          Opciones extra
        </h1>

        <Extraoption
          @click.prevent="addExtra(extra)"
          v-for="extra in dish.extras"
          :key="extra.name"
          :extra="extra"
        ></Extraoption>
      </div>
    </div>

    <!-- PRUEBA PANTALLA GRANDE BOTON A UN COSTADO lg:fixed lg:w-36 lg:bottom-1/2 lg:right-3 lg:shadow-xl-->
    <div
      class="z-20 bg-indigo-600 text-gray-100 w-full mt-4 sticky bottom-0 uppercase font-bold flex"
    >
      <div
        class="w-1/3 lg:w-1/6 text-xs lg:text-xl font-medium flex flex-col items-center justify-center bg-indigo-100 text-indigo-900 lowercase"
      >
        <p>Cal: {{ completeCal }}</p>
        <p>$ {{ completePrice }}</p>
      </div>
      <button
        @click.prevent="toggleOrder"
        class="w-full md:text-xl  p-4 focus:outline-none focus:bg-indigo-700"
      >
        Ordenar
      </button>
    </div>
    <Footer></Footer>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div v-if="isOrdering" class="fixed z-20 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div
              class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
            >
              <!-- Heroicon name: check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {{ dish.name }} {{ optionsSelected }}
              </h3>
              <div class="mt-2">
                <div class="flex w-full ">
                  <div class="w-1/2">
                    <ul class="p-4 text-gray-500 text-sm">
                      <li
                        v-for="(ingredient, index) in dish.ingredients"
                        :key="index"
                      >
                        &#8226; {{ ingredient }}
                      </li>
                    </ul>
                  </div>
                  <div v-if="extrasSelected.length" class="border-l w-1/2">
                    <ul class="p-4 text-gray-500 text-sm">
                      <li v-for="(extra, index) in extrasSelected" :key="index">
                        &#8226; {{ extra.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense"
          >
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-3 sm:text-sm"
            >
              Comer Aquí
            </button>
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm"
            >
              Para llevar
            </button>
            <button
              @click.prevent="toggleOrder"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            >
              Cambiar pedido
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MenuService from "@/services/MenuService.js";
import Radioptions from "../components/Radioptions.vue";
import Extraoption from "../components/Extraoption.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Navbar,
    Radioptions,
    Extraoption,
    Footer
  },
  props: {
    id: {}
  },
  data() {
    return {
      dish: null,
      img: null,
      optprice: null,
      optcal: null,
      isOrdering: false
    };
  },
  created() {
    MenuService.getDish(this.id)
      .then(response => {
        this.dish = response.data;
        this.img = response.data.img;
        this.precio = this.dish.price;
        this.calorias = this.dish.calories;
      })
      .catch(error => {
        console.log(error);
      });
  },
  emits: {},
  methods: {
    addExtra: function(extra) {
      extra.selected = !extra.selected;
      if (extra.selected) {
        this.dish.price += extra.extraprice;
        this.dish.calories += extra.extracal;
      } else {
        this.dish.price -= extra.extraprice;
        this.dish.calories -= extra.extracal;
      }
    },
    updatePrice: function() {
      this.optprice = 0;
      this.optcal = 0;
      for (let groups of this.dish.radioptions) {
        for (let opt of groups.options) {
          if (opt.selected) {
            this.optprice += opt.extraprice;
            this.optcal += opt.extracal;
            console.log(this.dish);
          }
        }
      }
    },
    toggleOrder() {
      this.isOrdering = !this.isOrdering;
    }
  },
  computed: {
    completePrice() {
      return this.optprice + this.dish.price;
    },
    completeCal() {
      return this.optcal + this.dish.calories;
    },
    extrasSelected() {
      var selectedExtras = [];
      for (let extra of this.dish.extras) {
        if (extra.selected) {
          selectedExtras.push(extra);
        }
      }
      return selectedExtras;
    },

    optionsSelected() {
      var selectedOptions = "";
      for (let extra of this.dish.radioptions) {
        for (let opt of extra.options)
          if (opt.selected) {
            selectedOptions += " " + opt.name;
          }
      }
      return selectedOptions;
    }
  }
};
</script>

<style></style>
